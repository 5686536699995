<template>
  <vx-card>
    <div class="vx-col sm:w-1/1 w-full mb-base">
      <vs-tabs>
        <vs-tab label="Shipment Planning 2 Report">
          <div class="tab-text">
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Territory</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  class="selectExample"
                  v-model="selectedTerritories"
                  :options="optionTerritories"
                  :multiple="true"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  :custom-label="customLabel"
                  track-by="id"
                >
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Shipment Plan Date</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <date-range-picker
                  ref="picker"
                  :opens="center"
                  :singleDatePicker="false"
                  :timePicker="false"
                  :timePicker24Hour="false"
                  :showWeekNumbers="true"
                  :showDropdowns="true"
                  :autoApply="true"
                  v-model="dateRange"
                  class="w-full"
                >
                  <template v-slot:input="picker" style="width: 100%">
                    {{ dateFormat(picker.startDate) }} -
                    {{ dateFormat(picker.endDate) }}
                  </template>
                </date-range-picker>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Shipment No.</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  class="selectExample"
                  v-model="selectedShipmentNo"
                  :options="optionShipmentNo"
                  :multiple="true"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  :custom-label="customLabel"
                  track-by="id"
                >
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Pi No.</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  class="selectExample"
                  v-model="selectedPiNo"
                  :options="optionPiNo"
                  :multiple="true"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  :custom-label="customLabel"
                  track-by="id"
                >
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Shipment Cost No.</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  class="selectExample"
                  v-model="selectedShipmentCost"
                  :options="optionShipmentCost"
                  :multiple="true"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  :custom-label="customLabel"
                  track-by="id"
                >
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Fwd Agent No.</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  class="selectExample"
                  v-model="selectedFwdAgent"
                  :options="optionFwdAgent"
                  :multiple="true"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  :custom-label="customLabel"
                  track-by="id"
                >
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-button color="success" @click="handleDrawTable()"
                  >Get Data</vs-button
                >
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col md:w-1/1 w-full mb-base">
                <ShipmentPlanning2Table
                  :territoryIDs="this.territoryIDs"
                  :shipmentNoIDs="this.shipmentNoIDs"
                  :piNoIDs="this.piNoIDs"
                  :shipmentCostNoIDs="this.shipmentCostNoIDs"
                  :fwdAgentNoIDs="this.fwdAgentNoIDs"
                  :startDate="this.dateRange.startDate"
                  :endDate="this.dateRange.endDate"
                  :draw="this.draw"
                />
              </div>
            </div>
          </div>
        </vs-tab>
        <vs-tab label="Export">
          <div class="tab-text">
            <data-table-export></data-table-export>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </vx-card>
</template>
<script>
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import DataTableExport from './DataTableExport.vue';
import ShipmentPlanning2Table from './ShipmentPlanning2Table.vue';
import { mapActions, mapState } from 'vuex/dist/vuex.common.js';
export default {
  components: {
    DateRangePicker,
    Datepicker,
    DataTableExport,
    ShipmentPlanning2Table,
  },
  data() {
    return {
      baseUrl: '/api/report/v1/territories',
      selectedTerritories: [],
      optionTerritories: [],
      selectedShipmentNo: [],
      optionShipmentNo: [],
      selectedPiNo: [],
      optionPiNo: [],
      selectedShipmentCost: [],
      optionShipmentCost: [],
      selectedFwdAgent: [],
      optionFwdAgent: [],
      territoryIDs: [],
      shipmentNoIDs: [],
      piNoIDs: [],
      shipmentCostNoIDs: [],
      fwdAgentNoIDs: [],
      territoryCodes: [],
      territoryNames: [],
      draw: 0,
      dateRange: {
        startDate: null,
        endDate: null,
      },
    };
  },
  methods: {
    ...mapActions({
      getShipmentNo: 'reportShipmentPlanning2/getShipmentNo',
      getPiNo: 'reportShipmentPlanning2/getPiNo',
      getShipmentCostNo: 'reportShipmentPlanning2/getShipmentCostNo',
      getFwdAgentNo: 'reportShipmentPlanning2/getFwdAgentNo',
    }),
    customLabel(val) {
      if (val) {
        return !val.code ? `${val.name}` : `(${val.code}) ${val.name}`;
      }
    },
    handleClose() {
      this.detail = false;
    },

    handleOpen() {
      this.detail = true;
    },
    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format('dddd, MMMM Do YYYY');
      }
      return a;
    },
    handleDrawTable() {
      this.draw++;
    },
    getTerritories() {
      this.$http.get('/api/report/v1/master/territory').then((resp) => {
        this.optionTerritories = resp.data.records;
      });
    },
  },
  mounted() {
    this.getTerritories();
    this.getShipmentNo();
    this.getPiNo();
    this.getShipmentCostNo();
    this.getFwdAgentNo();
    this.draw++
  },
  watch: {
    selectedTerritories(val) {
      let territoryIDs = [];
      let territoryCodes = [];
      let territoryNames = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          territoryIDs.push(e.id);
          territoryCodes.push(e.code);
          territoryNames.push(e.name);
        }
      });

      this.territoryIDs = territoryIDs;
      this.territoryCodes = territoryCodes;
      this.territoryNames = territoryNames;
    },
    selectedShipmentNo(val) {
      let shipmentNoIDs = [];

      val.forEach((e) => {
        if (e.name != '') {
          shipmentNoIDs.push(e.name);
        }
      });

      this.shipmentNoIDs = shipmentNoIDs;
    },
    selectedPiNo(val) {
      let piNoIDs = [];

      val.forEach((e) => {
        if (e.name != '') {
          piNoIDs.push(e.name);
        }
      });

      this.piNoIDs = piNoIDs;
    },
    selectedShipmentCost(val) {
      let shipmentCostNoIDs = [];

      val.forEach((e) => {
        if (e.name != '') {
          shipmentCostNoIDs.push(e.name);
        }
      });

      this.shipmentCostNoIDs = shipmentCostNoIDs;
    },
    selectedFwdAgent(val) {
      let fwdAgentNoIDs = [];

      val.forEach((e) => {
        if (e.name != '') {
          fwdAgentNoIDs.push(e.name);
        }
      });

      this.fwdAgentNoIDs = fwdAgentNoIDs;
    },
    shipmentNoList(val) {
      this.optionShipmentNo = val;
    },
    piNoList(val) {
      this.optionPiNo = val;
    },
    shipmentCostNoList(val) {
      this.optionShipmentCost = val;
    },
    fwdAgentNoList(val) {
      this.optionFwdAgent = val;
    },
  },
  computed: {
    ...mapState({
      shipmentNoList: (state) => state.reportShipmentPlanning2.shipmentNoList,
      piNoList: (state) => state.reportShipmentPlanning2.piNoList,
      shipmentCostNoList: (state) =>
        state.reportShipmentPlanning2.shipmentCostNoList,
      fwdAgentNoList: (state) => state.reportShipmentPlanning2.fwdAgentNoList,
    }),
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
.core-leave-to

/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
